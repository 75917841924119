import SmoothScroll from 'smooth-scroll'
const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 300,
    offset: function (anchor, navigation) {
        if (screen.width <= 640) {
            return 60
        } else {
            return 60
        }
    },
    easing: 'easeInOutQuint',
    updateURL: false,
    popstate: false
})


// AOS
import AOS from 'aos';
// アニメーションの設定
AOS.init({
    delay: 0,
    offset: 200,
    once: true,
    duration: 800,
});

if(document.querySelector("#drawer")){
    const drawer = document.querySelector("#drawer")
    const nav = document.querySelector("#nav")
    const navLink = document.querySelectorAll("#nav a")
    const close = document.querySelector("#close")

    drawer.addEventListener("click", () => {
        if(nav.classList.contains("open")){
            nav.classList.remove("open")
        }else{
            nav.classList.add("open")
        }
    })

    close.addEventListener("click", () => {
        if(nav.classList.contains("open")){
            nav.classList.remove("open")
        }else{
            nav.classList.add("open")
        }
    })

    navLink.forEach(item => {
        item.addEventListener("click", () => {
            if(nav.classList.contains("open")){
                nav.classList.remove("open")
            }else{
                nav.classList.add("open")
            }
        })
    })
}



// ボタンの切り替え
const bntHover = document.querySelectorAll('.bnt_hover img');
bntHover.forEach((item, i) => {
    item.addEventListener('mouseover', target => {
        const src = target.currentTarget.getAttribute('src');
        target.currentTarget.setAttribute('src', src.replace('_off', '_on'));
    });
    item.addEventListener('mouseout', target => {
        const src = target.currentTarget.getAttribute('src');
        target.currentTarget.setAttribute('src', src.replace('_on', '_off'));
    });
});
